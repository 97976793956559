import React, { useContext } from "react";
import { ThemeContext } from "context/ThemeContext";
import useTranslations from "hooks/useTranslations";
import Navigation from "components/navigation";
import SEO from "components/seo";
import { mq } from "utils/helper";

export default function Index() {
  const { colors } = useContext(ThemeContext);
  const tr = useTranslations();
  return (
    <div>
      <SEO metadata={{ title: "Ghouta - Chemical Attacks" }} />
      <div
        css={{
          position: "relative",
        }}
      >
        <video
          playsInline
          autoPlay
          muted
          loop
          poster="poster.jpg"
          css={{
            objectFit: "cover",
            height: "100vh",
            width: "100%",
            position: "sticky",
            top: 0,
            left: 0,
          }}
        >
          <source src="/assets/videos/ghouta.mp4" type="video/mp4" />
          Sorry, your browser doesnt support embedded videos.
        </video>

        <div
          css={{
            position: "absolute",
            zIndex: 2,
            top: "0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            overflow: "scroll",
          }}
        >
          <h1
            css={{
              marginTop: "2rem",
              marginBottom: "2rem",
              color: colors.light,
              fontSize: "45px",
              fontWeight: "bold",
              letterSpacing: "2.88px",
              lineHeight: "41px",
              textAlign: "center",
              [mq[0]]: {
                marginTop: "15rem",
              },
            }}
          >
            {tr("ghouta title")}
          </h1>
          <div
            css={{
              boxSizing: "border-box",
              height: "1px",
              width: "900px",
              border: `1.8px solid ${colors.primary}`,
              [mq[0]]: {
                width: "85%",
              },
            }}
          />

          <p
            css={{
              marginTop: "2rem",
              marginBottom: "2rem",
              color: colors.light,
              width: "890px",
              fontSize: "25px",
              letterSpacing: "0.29px",
              lineHeight: "34px",
              textAlign: "center",
              [mq[0]]: {
                width: "auto",
                padding: "1rem",
              },
            }}
          >
            {tr("ghouta heading 1")}
          </p>

          <p
            css={{
              marginTop: "2rem",
              marginBottom: "2rem",
              color: colors.light,
              width: "890px",
              fontSize: "25px",
              letterSpacing: "0.29px",
              lineHeight: "34px",
              textAlign: "center",
              [mq[0]]: {
                width: "auto",
                padding: "1rem",
              },
            }}
          >
            {tr("ghouta heading 2")}
          </p>

          <div
            css={{
              boxSizing: "border-box",
              height: "1px",
              width: "900px",
              border: `1px solid ${colors.primary}`,
              [mq[0]]: {
                width: "85%",
              },
            }}
          />

          <Navigation place="homepage" />
        </div>

        <div
          css={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            transition: "1s",
            background:
              "linear-gradient(180deg, rgba(0,0,0,0.50) 0%, rgba(175,58,0,0.22) 100%)",
          }}
        />
      </div>
    </div>
  );
}
